<template>
  <v-app>
    <v-app-bar app class="NavBar">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/"><v-img contain height="50px" width="150px" alt="Lourdes Logo" src="@/assets/logo-lourdes-sm.png"/></router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn disabled class="success ml-2">Ingreso</v-btn>
      <v-btn disabled class="error ml-2">Salir</v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary dark>
      <v-layout mt-4 column align-center>
        <v-flex>
          <h1 class="tituloMenu mb-3">MENÚ</h1>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><router-link to="/" style="color:#fff">Home</router-link></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><router-link to="/abecedario" style="color:#fff">The Alphabet</router-link></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><router-link to="/music-videos" style="color:#fff">Music Videos</router-link></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><router-link to="/games" style="color:#fff">Games</router-link></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><router-link to="/cuerpo" style="color:#fff">The human body</router-link></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><a href="/birthday.php" style="color:#fff">My Birthday</a></v-btn>
        </v-flex>
        <v-flex>
          <v-btn class="tituloMenu text-none success my-3"><a href="/find.html" style="color:#fff">Find Señora Wiegand!</a></v-btn>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data(){
    return {
      drawer:true
      }
  },
};
</script>

<style>
  .NavBar{
    background:rgba(134, 218, 233, 0.473) !important;
  }
  .tituloMenu{
    color: #fff;
  }
  a 
  {  text-decoration: none;}

</style>