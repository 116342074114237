import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/abecedario',
        name: 'Abecedario',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Abecedario.vue')
    },
    {
        path: '/music-videos',
        name: 'Music-Videos',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Music-Videos.vue')
    },
    {
        path: '/games',
        name: 'Games',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Games.vue')
    },
    {
        path: '/cuerpo',
        name: 'Cuerpo',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Cuerpo.vue')
    },
    {
        path: '/black-history-month',
        name: 'Black-History-Month',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Black-History-Month.vue')
    },
    {
        path: '/body-parts',
        name: 'Body',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Body.vue')
    },
    {
        path: '/hispanic-market',
        name: 'Market',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/Market.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router